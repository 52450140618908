import React from "react";
import BannerImage from "../../sub-components/banner-image";

export default function Mandoobee() {
    const plans = [
        {
            title: "Basic",
            price: "AED 25",
            yearlyPrice: "AED 300",
            features: [
                "Smart Attendance",
                "Smart Service",
                "Dashboard",
                "Employee Directory",
                "Task Management",
                "Meeting Management",
                "Bilingual (EN/AR)",
            ],
            note: "* Integration with any third-party system is excluded. Additional charges for customization and on-demand training if any.",
        },
        {
            title: "Typical",
            price: "AED 45",
            yearlyPrice: "AED 540",
            features: [
                "Smart Document & E-Approvals",
                "Project Management",
                "Group Management",
                "MoM Management",
                "1GB Storage Cloud Space",
            ],
            note: "* Integration with any third-party system is excluded. Additional charges for customization and on-demand training if any.",
        },
        {
            title: "Advanced",
            price: "AED 95",
            yearlyPrice: "AED 1140",
            features: [
                "Whitelabeling",
                "AI Note Assistant",
                "All Mandoobee Bot",
                "Chat",
                "Asset Register",
                "Shared Folder",
                "Links & Video",
                "2GB Storage Cloud Space",
            ],
            note: "* Integration with any third-party system is excluded. Additional charges for customization and on-demand training if any.",
        },
        {
            title: "Enterprise",
            price: "Contact Us",
            features: [
                "All additional features",
                "Customizable IT Support",
                "Custom Document Engine",
                "IoT Gate Pass",
                "White labeling",
                "Unlimited Storage",
            ],
            contact: "info@meerana.ae",
        },
    ];

    return (
        <div className="bg-white">
            <BannerImage />
            <div >
                {/* Smart Office App Section */}
                <section className="px-4 lg:px-16 py-16 bg-white">
                    <div className="max-w-7xl mx-auto flex flex-col-reverse lg:flex-row items-center justify-between gap-8">
                        {/* Text Content */}
                        <div className="w-full lg:w-1/2 mb-8 lg:mb-0 text-center lg:text-left">
                            <h2 className="text-3xl sm:text-4xl font-extrabold text-[#0F2A6B] mb-6">Mandoobee App</h2>
                            <p className="text-gray-700 mb-6 leading-relaxed text-sm sm:text-base">
                                Mandoobee is an innovative solution designed to streamline and simplify the daily operations of an office. The app allows employees to punch in and out of the office with ease, ensuring accurate time tracking and efficient payroll management.
                            </p>
                            <p className="text-gray-700 mb-6 leading-relaxed text-sm sm:text-base">
                                Additionally, the app features smart document management, allowing users to store, access, and collaborate on important files from anywhere. The meeting management feature makes scheduling and organizing meetings a breeze, allowing teams to stay on top of their schedules and remain productive.
                            </p>
                            {/* Buttons */}
                            <div className="flex flex-col sm:flex-row gap-4 items-center justify-center lg:justify-start">
                                <a href="https://apple.com" target="_blank" rel="noopener noreferrer">
                                    <img
                                        src="/assets/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg"
                                        alt="Download on App Store"
                                        className="w-32 sm:w-36"
                                    />
                                </a>
                                <a href="https://play.google.com" target="_blank" rel="noopener noreferrer">
                                    <img
                                        src="/assets/GetItOnGooglePlay_Badge_Web_color_English.png"
                                        alt="Get it on Google Play"
                                        className="w-32 sm:w-36"
                                    />
                                </a>
                            </div>
                        </div>
                        {/* Image Section */}
                        <div className="w-full lg:w-1/2 flex justify-center">
                            <img
                                src="/assets/smart-office-app.png"
                                alt="Smart Office App"
                                className="w-full max-w-[400px] sm:max-w-[500px] lg:max-w-[600px] rounded-lg"
                            />
                        </div>
                    </div>
                </section>

                {/* New Section: Why Mandoobee? */}
                <section className="px-4 lg:px-16 py-24 bg-gradient-to-r from-blue-50 to-blue-100 rounded-lg shadow-md">
                    <h2 className="text-4xl font-extrabold text-[#0F2A6B] text-center mb-8">Why Mandoobee?</h2>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                        <div className="bg-white p-6 rounded-md shadow-lg text-center">
                            <img src="/assets/icons/price-reduction.jpg" alt="Cost Icon" className="mx-auto w-16 mb-4" />
                            <h3 className="text-2xl font-bold text-[#0F2A6B] mb-2">Reduce Costs</h3>
                            <p className="text-md text-[#0F2A6B]">
                                Reduce high operational costs to stay competitive in a demanding market.
                            </p>
                        </div>
                        <div className="bg-white p-6 rounded-md shadow-lg text-center">
                            <img src="/assets/icons/compliance.jpg" alt="Compliance Icon" className="mx-auto w-16 mb-4" />
                            <h3 className="text-2xl font-bold text-[#0F2A6B] mb-2">Ensure Compliance</h3>
                            <p className="text-md text-[#0F2A6B]">
                                Meet increasing regulatory demands effortlessly with tailored solutions.
                            </p>
                        </div>
                        <div className="bg-white p-6 rounded-md shadow-lg text-center">
                            <img src="/assets/icons/productivity.jpeg" alt="Productivity Icon" className="mx-auto w-16 mb-4" />
                            <h3 className="text-2xl font-bold text-[#0F2A6B] mb-2">Boost Productivity</h3>
                            <p className="text-md text-[#0F2A6B]">
                                Empower teams with tools for efficient collaboration in hybrid work models.
                            </p>
                        </div>
                    </div>
                </section>

                {/* New Section: Mandoobee Marketing */}
                <section className="px-4 lg:px-16 py-12 bg-[#0F2A6B] text-white rounded-lg shadow-md">
                    <h2 className="text-4xl font-extrabold text-center mb-8">Mandoobee Marketing</h2>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                        <div className="bg-white p-6 rounded-md shadow-lg text-center">
                            <img src="/assets/icons/scalable.jpeg" alt="Scalable Icon" className="mx-auto w-16 mb-4" />
                            <h3 className="text-2xl font-bold text-[#0F2A6B] mb-2">Scalable Solutions</h3>
                            <p className="text-md text-[#0F2A6B]">
                                Tailored to the unique needs of UAE businesses, ensuring growth and scalability.
                            </p>
                        </div>
                        <div className="bg-white p-6 rounded-md shadow-lg text-center">
                            <img src="/assets/icons/automation.jpeg" alt="Automation Icon" className="mx-auto w-16 mb-4" />
                            <h3 className="text-2xl font-bold text-[#0F2A6B] mb-2">AI-Powered Tools</h3>
                            <p className="text-md text-[#0F2A6B]">
                                Automate operations with cutting-edge AI tools for greater efficiency.
                            </p>
                        </div>
                        <div className="bg-white p-6 rounded-md shadow-lg text-center">
                            <img src="/assets/icons/collaboration.jpeg" alt="Collaboration Icon" className="mx-auto w-16 mb-4" />
                            <h3 className="text-2xl font-bold text-[#0F2A6B] mb-2">Enhanced Collaboration</h3>
                            <p className="text-md text-[#0F2A6B]">
                                Enable seamless communication and teamwork, whether remote or in-office.
                            </p>
                        </div>
                    </div>
                </section>

                <header className="text-center mb-10 mt-16">
                    <h1 className="text-4xl font-bold text-[#0F2A6B]">Mandoobee (Smart Office) Plans</h1>
                    <p className="text-lg text-[#0F2A6B]">On Cloud (AED)</p>
                </header>

                <section className="flex flex-wrap justify-center gap-6 px-4 lg:px-16">
                    {plans.map((plan, index) => (
                        <div
                            key={index}
                            className="bg-[#0F2A6B] text-white rounded-lg shadow-lg w-full md:w-[300px] lg:w-[350px] p-6 flex flex-col justify-between items-center text-center"
                        >
                            <div>
                                <h2 className="text-2xl font-bold">{plan.title}</h2>
                                <p className="text-4xl font-semibold my-4">
                                    {plan.price}{" "}
                                    {plan.yearlyPrice && (
                                        <span className="text-sm font-light block mt-1">
                                            /user/month billed yearly at {plan.yearlyPrice}
                                        </span>
                                    )}
                                </p>
                                <ul className="mt-4 space-y-2 text-sm">
                                    {plan.features.map((feature, i) => (
                                        <li key={i}>✔ {feature}</li>
                                    ))}
                                </ul>
                                {plan.note && (
                                    <p className="text-xs mt-4">{plan.note}</p>
                                )}
                                {plan.contact && (
                                    <p className="mt-6 text-sm">
                                        Email us at: <a href={`mailto:${plan.contact}`} className="underline">{plan.contact}</a>
                                    </p>
                                )}
                            </div>
                            {plan.contact && (
                                <div className="mt-6">
                                    <a
                                        href={`mailto:${plan.contact}`}
                                        className="bg-white text-[#0F2A6B] px-6 py-2 rounded-md hover:shadow-md"
                                    >
                                        Contact Us
                                    </a>
                                </div>
                            )}
                        </div>
                    ))}
                </section>

                <footer className="text-center mt-10">
                    <p className="text-lg font-bold text-[#0F2A6B]">One Month Free Trial</p>
                </footer>
            </div>
        </div>
    );
}
